<template>
    <div class="emby-subtext-container">
        <p class="emby-subtext" :class="index === 2 ? 'emby-margin-text' : ''" v-for="(item, index) in texts"
            :key="index">
            {{ $t(item) }}
        </p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            texts: [
                'faq.emby.whatIsEmby',
                'faq.emby.usersCanStream',
                'faq.emby.haveaServerMedia',
                'faq.emby.serverSideSupport',
                'faq.emby.clientSideSupport',
                'faq.emby.allSupports',
                'faq.emby.youMightNeedEmby',
                'faq.emby.thisGuideWillTake'
            ]
        }
    }
}
</script>

<style scoped>
.emby-subtext-container {
    margin-bottom: 20px;
}

.emby-subtext {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;

    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;

    word-wrap: break-word;
}

.emby-margin-text {
    margin: 16px 0;
}
</style>
